import React, { useState } from 'react'
import axios from 'redaxios'
import { Form, Formik, FieldArray, Field, useField } from 'formik'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
registerLocale('de', de)
setDefaultLocale('de')
import 'react-datepicker/dist/react-datepicker.css'

import { firestore } from '../firebase'
import {
  validationSchema,
  initialDataArtwork,
  texts,
  schemaArtist,
} from './BewerbungFormDefinition'
import BewerbungFormArtwork from './BewerbungFormArtwork'
import ImageUpload from './ImageUpload'
import appConfig from '../appConfig'

const VITA_SHORT_MAX_LENGTH = 300
export const helpMailTo =
  "mailto:bewerbung@kuboshow.de?subject=Problem bei Bewerbung / Application form problems&body=Oh, da ist etwas schiefgelaufen.%0D%0AWas denn nur?%0D%0AKannst Du Dein Problem beschreiben?%0D%0A%0D%0A%0D%0AOh, something's gone wrong.%0D%0AWell, what is it?%0D%0ACan you describe your trouble?"
const portraitFotoHeight = '150px'

const FormStart = ({ formLanguage }) => (
  <div className="row mb-3">
    <div className="col-12 col-md-8">
      <p>
        <strong>{texts[formLanguage].descriptionTop1a}</strong>
        <br />
        {texts[formLanguage].descriptionTop1b}
      </p>
      {/* <p>
              <strong>{texts[formLanguage].descriptionTop2a}</strong>
              <br />
              {texts[formLanguage].descriptionTop2b1}
              <strong>
                <i>{texts[formLanguage].descriptionTop2b2}</i>
              </strong>
              {texts[formLanguage].descriptionTop2b3}
            </p>
            <p>
        <strong>{texts[formLanguage].browsers1}</strong>
        <br />
        {texts[formLanguage].browsers2}
      </p> */}
      <p>
        <strong>{texts[formLanguage].descriptionTop3a}</strong>{' '}
        {texts[formLanguage].descriptionTop3b}.
      </p>
    </div>
  </div>
)
// eslint-disable-next-line react/prop-types
export const SectionHeading = ({ title }) => (
  <h2 className="my-2 text-primary">{title}</h2>
)

const FieldPortraitFile = ({ buttonText }) => {
  const [portraitFileField, portraitFileMeta, portraitFileHelpers] =
    useField('portrait_file')

  const onPortraitFileChange = (fileName) => {
    console.log('onPortraitFileChange', fileName)
    if (!portraitFileMeta.touched) portraitFileHelpers.setTouched(true)
    portraitFileHelpers.setValue(fileName?.length > 0 ? fileName : '')
  }

  return (
    <div
      className="col-12 col-sm-4 d-flex flex-wrap"
      style={{ maxHeight: portraitFotoHeight, minHeight: '80px' }}
    >
      <ImageUpload
        filename={portraitFileField.value}
        setExternalFileName={onPortraitFileChange}
        dropzoneClassName="w-100"
        imageHeight={portraitFotoHeight}
        buttonText={buttonText}
      />
      {portraitFileMeta.touched && portraitFileMeta.error ? (
        <div className="text-danger mt-1">{portraitFileMeta.error}</div>
      ) : null}
    </div>
  )
}

const PersonalFieldColumn = ({ type = 'text', ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <div className="col-sm-6 col-md-5 mb-3">
      <input
        type={type}
        {...field}
        {...props}
        className={`form-control ${
          meta.touched && meta.error ? 'is-invalid' : ''
        }`}
        autoComplete="off"
        onChange={({ target }) => {
          helpers.setValue(target.value)
          helpers.setTouched(true)
        }}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger mt-1">{meta.error}</div>
      ) : null}
    </div>
  )
}
const BirthdayFieldColumn = ({ type = 'text', formLanguage = 'de', ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <div className="col-sm-6 col-md-5 mb-3">
      <DatePicker
        {...field}
        {...props}
        locale={formLanguage}
        dateFormat={formLanguage === 'de' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className={`form-control ${
          meta.touched && meta.error ? 'is-invalid' : ''
        }`}
        // autoComplete="off"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(date) => {
          helpers.setValue(date)
          helpers.setTouched(true)
        }}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger mt-1">{meta.error}</div>
      ) : null}
    </div>
  )
}

const BewerbungForm = ({
  formLanguage,
  bewerbungInitialData,
  loginMode = false,
}) => {
  const [isErrorShown, setIsErrorShown] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  // const [previousArtworks, setPreviousArtworks] = useState([])

  return (
    <Formik
      initialValues={bewerbungInitialData}
      onSubmit={async (values) => {
        const { artworks, ...artist } = values
        try {
          await new Promise((r) => setTimeout(r, 1500))
          const succesfulLoginMode = loginMode && artist.artistId ? true : false
          console.log('Submitted-Data: ', values)
          // Save artist data
          const docRefArtist = succesfulLoginMode
            ? await firestore.collection('artists').doc(artist.id)
            : await firestore.collection('artists').doc()
          console.log('document reference id', docRefArtist.id)

          const generatedArtistId = succesfulLoginMode
            ? artist.artistId
            : Math.random().toString(36).substr(2, 9).toUpperCase()
          if (succesfulLoginMode) {
            console.log('update existing artist')
            docRefArtist.update({
              ...artist,
              join20241: true,
              userAgent: window?.navigator?.userAgent ?? '',
            })
          } else {
            console.log('create new artist')
            docRefArtist.set({
              ...artist,
              join20241: true,
              artistId: generatedArtistId,
              userAgent: window?.navigator?.userAgent ?? '',
            })
          }
          // Save artworks
          // delete existing ones
          if (succesfulLoginMode) {
            const oldOnes = await firestore
              .collection('artworks')
              .where('artistId', '==', artist.id)
              .get()
            console.log('delete existing ones', oldOnes?.docs || [])
            const batchOld = firestore.batch()
            oldOnes.forEach((doc) => {
              const docRef = doc.ref
              const docData = doc.data()
              if (docData.fair === appConfig.currentFairYear) {
                batchOld.set(docRef, {
                  ...docData,
                  deleted: true,
                })
              }
            })
            await batchOld.commit()
            console.log('batch old complete')
          }

          // write new ones
          const batchNew = firestore.batch()
          artworks.forEach((artwork) => {
            const docRefArtwork = firestore.collection('artworks').doc()
            batchNew.set(docRefArtwork, {
              ...artwork,
              fair: appConfig.currentFairYear,
              files: artwork.files.filter((f) => f !== 'deleted'),
              artistId: docRefArtist.id,
            })
          })
          await batchNew.commit()

          const mailResult = await axios({
            method: 'post',
            url: appConfig.functions.onApplicationSent,
            data: JSON.stringify({
              to: values.email,
              // to: 'schon.florian@gmail.com',
              last_name: values.last_name,
              first_name: values.first_name,
              artistId: generatedArtistId,
            }),
          })
          console.log('axios result: ', mailResult)
          if (mailResult.data === 'success') {
            setIsFormSubmitted(true)
            setIsErrorShown(false)
          } else {
            setIsFormSubmitted(true)
            setIsErrorShown(false)
            // setIsErrorShown(true)
            const docRefFailedApp = await firestore
              .collection('failed_applications23')
              .doc()
            docRefFailedApp.set({
              ...artist,
              userAgent: window?.navigator?.userAgent ?? '',
            })
          }
        } catch (error) {
          console.error(error, error?.message)
          setIsErrorShown(true)
          const docRefFailedApp2 = await firestore
            .collection('failed_applications23')
            .doc()
          docRefFailedApp2.set({
            ...artist,
            userAgent: window?.navigator?.userAgent ?? '',
          })
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        submitCount,
        isSubmitting,
        isValidating,
        // setFieldValue,
      }) => {
        return (
          <Form>
            {!loginMode && <FormStart formLanguage={formLanguage} />}{' '}
            {/* <div className="row">
              <div className="col-5">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div>
              <div className="col-4">
                <pre> {JSON.stringify(isFormSubmitted, null, 2)}</pre>
                <pre> {JSON.stringify(isErrorShown, null, 2)}</pre>
                <pre> {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className="col-3">
                <pre>{JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
            <SectionHeading title={texts[formLanguage].headingPersonalData} />
            <div className="form-row">
              <PersonalFieldColumn
                name="first_name"
                placeholder={schemaArtist.first_name[formLanguage]}
              />
              <PersonalFieldColumn
                name="last_name"
                placeholder={schemaArtist.last_name[formLanguage]}
              />
            </div>
            <div className="form-row">
              <PersonalFieldColumn
                name="email"
                type="email"
                placeholder={schemaArtist.email[formLanguage]}
              />
              <BirthdayFieldColumn
                name="birthday"
                placeholder={schemaArtist.birthday[formLanguage]}
                // formLanguage={formLanguage}
              />
            </div>
            <div className="form-row">
              <PersonalFieldColumn
                name="homepage"
                placeholder={schemaArtist.homepage[formLanguage]}
              />
              <PersonalFieldColumn
                name="instagram"
                placeholder={schemaArtist.instagram[formLanguage]}
              />
            </div>
            <div className="form-row">
              <PersonalFieldColumn
                name="address"
                placeholder={schemaArtist.address[formLanguage]}
              />
            </div>
            <div className="form-row">
              <PersonalFieldColumn
                name="mobile"
                placeholder={schemaArtist.mobile[formLanguage]}
              />
              <PersonalFieldColumn
                name="phone"
                placeholder={schemaArtist.phone[formLanguage]}
              />
            </div>
            <div className="form-row">
              <PersonalFieldColumn
                name="zipcode"
                maxLength="6"
                placeholder={schemaArtist.zipcode[formLanguage]}
              />
              <PersonalFieldColumn
                name="city"
                placeholder={schemaArtist.city[formLanguage]}
              />
            </div>
            <div className="row my-2">
              <div className="col-12">
                <SectionHeading title={texts[formLanguage].headingMyArtworks} />
                <p>{texts[formLanguage].descriptionArtworkData1}</p>
              </div>
            </div>
            <FieldArray name="artworks">
              {({
                form: { values, setValues, validateForm },
                ...fieldArrayHelpers
              }) => {
                const onAddClick = () => {
                  fieldArrayHelpers.push({
                    ...initialDataArtwork,
                    artworkIndex:
                      values?.artworks?.length > 0
                        ? Math.max(
                            ...values.artworks.map((x) => x.artworkIndex)
                          ) + 1
                        : 1,
                  })
                  validateForm()
                }

                const onRemoveClick = (idx) => {
                  const newArtworks = values.artworks.filter(
                    (artwork) => idx !== artwork.artworkIndex
                  )
                  setValues({
                    ...values,
                    artworks: newArtworks,
                  })
                }

                return (
                  <>
                    {/* <p>
                      Bereits erfasste Arbeiten sind ab sofort nicht mehr
                      editierbar. Neue Arbeiten kannst du gerne hinzufügen.
                    </p> */}
                    {values.artworks.map((artwork, index) => (
                      <BewerbungFormArtwork
                        key={index}
                        artworkArrayIndex={index}
                        onRemoveClick={() =>
                          onRemoveClick(artwork.artworkIndex)
                        }
                        restrictedMode={appConfig.restrictedMode}
                        formLanguage={formLanguage}
                      />
                    ))}
                    <button
                      className="btn btn-sm btn-secondary mt-1 mb-4 px-4"
                      onClick={onAddClick}
                      type="button"
                    >
                      {texts[formLanguage].addArtwork}
                    </button>
                  </>
                )
              }}
            </FieldArray>
            <br />
            <SectionHeading title={texts[formLanguage].headingMyVita} />
            <div className="form-row">
              <div className="col-12 col-sm-10 col-md-8">
                <Field
                  as="textarea"
                  name="vita_short"
                  rows="4"
                  maxLength={VITA_SHORT_MAX_LENGTH + 10}
                  placeholder={schemaArtist.vita_short[formLanguage]}
                  className="form-control"
                  autoComplete="off"
                />
                {touched.vita_short && errors.vita_short ? (
                  <div className="text-danger mt-1">{errors.vita_short}</div>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                {values.vita_short.length > VITA_SHORT_MAX_LENGTH ? (
                  <div className="text-danger">
                    {values.vita_short.length -
                      VITA_SHORT_MAX_LENGTH +
                      ' Zeichen zu viel'}
                  </div>
                ) : values.vita_short.length > 0 ? (
                  <div className="text-secondary">
                    {VITA_SHORT_MAX_LENGTH -
                      values.vita_short.length +
                      ' verbleibende Zeichen'}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-row mt-3 mb-3">
              <div className="col-12 col-sm-10 col-md-8">
                <Field
                  as="textarea"
                  name="vita_long"
                  rows="6"
                  placeholder={schemaArtist.vita_long[formLanguage]}
                  className="form-control"
                  autoComplete="off"
                />
                {touched.vita_long && errors.vita_long ? (
                  <div className="text-danger mt-1">{errors.vita_long}</div>
                ) : null}
              </div>
            </div>
            <SectionHeading
              title={texts[formLanguage].headingAddPortraitFile}
            />
            <div className="form-row">
              <FieldPortraitFile
                buttonText={schemaArtist.portrait_file[formLanguage]}
              />
            </div>
            <div className="form-row my-3">
              <div className="col-12 col-sm-10 col-md-8">
                <Field
                  as="textarea"
                  name="info"
                  rows="3"
                  placeholder={schemaArtist.info[formLanguage]}
                  className="form-control"
                  autoComplete="off"
                />
                {touched.info && errors.info ? (
                  <div className="text-danger mt-1">{errors.info}</div>
                ) : null}
              </div>
            </div>
            {!isFormSubmitted && (
              <button
                className="btn btn-secondary mb-3"
                type="submit"
                disabled={isSubmitting || isValidating || isFormSubmitted}
              >
                {isSubmitting && (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span>{texts[formLanguage].submitButton}</span>
              </button>
            )}
            {isFormSubmitted && (
              <p className="text-success h4 mt-3">
                {texts[formLanguage].submittedSuccess}
              </p>
            )}
            {!isFormSubmitted &&
              Object.keys(errors).length >= 1 &&
              submitCount >= 1 && (
                <p className="text-danger">
                  {texts[formLanguage].submittedInvalid1}
                  <br />
                  {texts[formLanguage].submittedInvalid2}{' '}
                  <a href={helpMailTo}>
                    {texts[formLanguage].submittedInvalid3}.
                  </a>
                </p>
              )}
            {isErrorShown && submitCount >= 1 && (
              <p className="text-danger">
                {texts[formLanguage].submittedFailure1}{' '}
                <a href={helpMailTo}>
                  {texts[formLanguage].submittedFailure2}.
                </a>
              </p>
            )}
            <p className="my-5">
              <strong>{texts[formLanguage].dataSecurity1}</strong>
              <br />
              {texts[formLanguage].dataSecurity2}
            </p>
          </Form>
        )
      }}
    </Formik>
  )
}

export default BewerbungForm
