/* eslint-disable react/prop-types */
import React from 'react'
import { useField, FieldArray } from 'formik'
import { schemaArtwork } from './BewerbungFormDefinition'
import BewerbungFormArtworkFile from './BewerbungFormArtworkFile'

const ErrorMessage = ({ meta }) => {
  if (meta.touched && meta.error && typeof meta.error === 'string')
    return <div className="invalid-feedback d-block">{meta.error}</div>
  return null
}

const BewerbungFormArtwork = ({
  artworkArrayIndex,
  onRemoveClick,
  formLanguage,
  restrictedMode = false,
}) => {
  const [titleField, titleMeta, titleHelpers] = useField(
    `artworks[${artworkArrayIndex}].title`
  )
  const [techniqueField, techniqueMeta, techniqueHelpers] = useField(
    `artworks[${artworkArrayIndex}].technique`
  )
  const [heightField, heightMeta, heightHelpers] = useField(
    `artworks[${artworkArrayIndex}].height`
  )
  const [widthField, widthMeta, widthHelpers] = useField(
    `artworks[${artworkArrayIndex}].width`
  )
  const [depthField, depthMeta, depthHelpers] = useField(
    `artworks[${artworkArrayIndex}].depth`
  )
  const [priceField, priceMeta, priceHelpers] = useField(
    `artworks[${artworkArrayIndex}].price`
  )
  const [infoField, infoMeta, infoHelpers] = useField(
    `artworks[${artworkArrayIndex}].info`
  )

  return (
    <div className="form-row artwork mt-1">
      {!restrictedMode && (
        <div className="ml-n4">
          <div className="rm-artwork" onClick={onRemoveClick}>
            <i className="entypo-cross" />
          </div>
        </div>
      )}
      <div className="">
        <div className="artwork-counter">
          <strong>{artworkArrayIndex + 1}</strong>
        </div>
      </div>
      <div className="col-12 col-sm-2">
        <input
          type="text"
          name={titleField.name}
          value={titleField.value}
          placeholder={schemaArtwork.title[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            titleHelpers.setValue(target.value)
            if (!titleMeta.touched) titleHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={titleMeta} />
      </div>
      <div className="col-12 col-sm-2">
        <input
          type="text"
          name={techniqueField.name}
          value={techniqueField.value}
          placeholder={schemaArtwork.technique[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            techniqueHelpers.setValue(target.value)
            if (!techniqueMeta.touched) techniqueHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={techniqueMeta} />
      </div>
      <div className="col-12 col-sm">
        <input
          type="text"
          name={heightField.name}
          value={heightField.value}
          placeholder={schemaArtwork.height[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            heightHelpers.setValue(target.value)
            if (!heightMeta.touched) heightHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={heightMeta} />
      </div>
      <div className="col-12 col-sm">
        <input
          type="text"
          name={widthField.name}
          value={widthField.value}
          placeholder={schemaArtwork.width[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            widthHelpers.setValue(target.value)
            if (!widthMeta.touched) widthHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={widthMeta} />
      </div>
      <div className="col-12 col-sm">
        <input
          type="text"
          name={depthField.name}
          value={depthField.value}
          placeholder={schemaArtwork.depth[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            depthHelpers.setValue(target.value)
            if (!depthMeta.touched) depthHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={depthMeta} />
      </div>
      <div className="col-12 col-sm">
        <input
          type="text"
          name={priceField.name}
          value={priceField.value}
          placeholder={schemaArtwork.price[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            priceHelpers.setValue(target.value)
            if (!priceMeta.touched) priceHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={priceMeta} />
      </div>
      <div className="col-12 col-sm">
        <input
          type="text"
          name={infoField.name}
          value={infoField.value}
          placeholder={schemaArtwork.info[formLanguage]}
          className="form-control"
          disabled={restrictedMode}
          onChange={({ target }) => {
            infoHelpers.setValue(target.value)
            if (!infoMeta.touched) infoHelpers.setTouched(true)
          }}
        />
        <ErrorMessage meta={infoMeta} />
      </div>
      <FieldArray name={`artworks[${artworkArrayIndex}].files`}>
        {({ form: { values } }) => {
          const currentLength = values.artworks[artworkArrayIndex].files.length

          return (
            <div className="col-12 col-sm-2 d-flex flex-nowrap">
              {Array.of(...Array(currentLength), currentLength).map(
                (file, filesArrayIndex) => {
                  const calcedImgHeight =
                    currentLength > 3
                      ? '40px'
                      : currentLength > 2
                      ? '50px'
                      : currentLength > 1
                      ? '60px'
                      : '80px'

                  return (
                    <div
                      key={filesArrayIndex}
                      className="mr-1"
                      style={{ maxHeight: calcedImgHeight }}
                    >
                      <BewerbungFormArtworkFile
                        filesArrayIndex={filesArrayIndex}
                        artworkArrayIndex={artworkArrayIndex}
                        formLanguage={formLanguage}
                        imgHeight={calcedImgHeight}
                      />
                    </div>
                  )
                }
              )}
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default BewerbungFormArtwork
