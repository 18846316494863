/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import useFirebaseUpload from '../hooks/useFirebaseUpload'
import { kbImagifyDyn } from './GalleryUtils'

const ImageUpload = ({
  filename,
  setExternalFileName,
  buttonText = 'Choose file',
  imgHeight = '80px',
  dropzoneClassName = '',
}) => {
  const [
    { dataResponse, isLoading, isError, progress },
    setFileData,
    // deleteFile,
  ] = useFirebaseUpload('images/', true)

  const onDropAccepted = useCallback((acceptedFiles) => {
    setFileData(acceptedFiles[0])
  }, [])

  const onDropRejected = useCallback((rejectedFiles) => {
    console.log('rejectedFiles: ', rejectedFiles)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: 'image/jpeg, image/png',
  })

  useEffect(() => {
    if (dataResponse === undefined) return
    console.log(dataResponse)
    dataResponse?.downloadUrl?.length > 0
      ? setExternalFileName(dataResponse.metadata.name)
      : setExternalFileName('')
  }, [dataResponse])

  return filename ? (
    <figure className="preview-figure m-0 h-100">
      <img
        src={kbImagifyDyn(filename)}
        style={{ height: imgHeight }}
        className="w-auto"
        alt="Upload image"
      />
      <div
        className="preview-overlay"
        style={{ height: imgHeight }}
        onClick={() => {
          setExternalFileName('')
        }}
      >
        <i className="entypo-trash" />
      </div>
    </figure>
  ) : (
    <div {...getRootProps({ className: 'dropzone ' + dropzoneClassName })}>
      <input {...getInputProps()} />
      {isLoading ? (
        <>
          <span>Upload...</span>
          <div className="progress w-100">
            <div
              className="progress-bar bg-info p-1"
              style={{ width: progress + '%' }}
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {`${progress} %`}
            </div>
          </div>
        </>
      ) : isError ? (
        <span>Upload Error</span>
      ) : (
        <span className="w-100">{buttonText}</span>
      )}
    </div>
  )
}

export default ImageUpload
