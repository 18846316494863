import { useCallback, useEffect, useState } from 'react'
import { storageRef } from '../firebase'

// SRC: https://github.com/aaronksaunders/simple-file-upload-hook/blob/master/src/hooks/useFirebaseUpload.ts

const useFirebaseUpload = (bucketFolderPath = '/', logProgress = false) => {
  const [dataResponse, setDataResponse] = useState(undefined)
  const [fileData, setFileData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [progress, setProgress] = useState(0)
  const clearError = () => setIsError(null)

  const deleteFile = useCallback(() => {
    if (dataResponse?.metadata?.name) {
      storageRef
        .child(bucketFolderPath + dataResponse.metadata.name)
        .delete()
        .then(() => {
          // console.log('deletion res: ', res)
          setDataResponse('')
          setFileData('')
        })
        .catch((_error) => {
          console.error(_error)
          setIsError(true)
        })
    }
  }, [dataResponse])

  const createUploadTask = useCallback(
    (_value) => {
      if (_value instanceof File) {
        let fName = `${new Date().getTime()}-${_value.name}`
        // setting the firebase properties for the file upload
        let uploadTask = storageRef.child(bucketFolderPath + fName)
        return uploadTask.put(_value)
      } else {
        let v = _value
        let fName = `${new Date().getTime()}.${v.format}`
        // setting the firebase properties for the file upload
        let uploadTask = storageRef.child(bucketFolderPath + fName)
        return uploadTask.putString(v.dataUrl, 'data_url')
      }
    },
    [bucketFolderPath]
  )

  const uploadData = useCallback(async () => {
    // initialize upload information
    setIsError(false)
    setIsLoading(true)
    setProgress(0)
    // handle a file upload or a dataUrl upload
    let uploadTask = createUploadTask(fileData)

    // wrap the whole thing in a try catch block to update the error state
    try {
      // tracking the state of the upload to assist in updating the UI
      uploadTask.on(
        'state_changed', // storageRef.TaskEvent.STATE_CHANGED,
        (_snapshot_progress) => {
          const progress = Math.round(
            (_snapshot_progress.bytesTransferred /
              _snapshot_progress.totalBytes) *
              100
          )
          if (logProgress)
            // console.log('Upload is ' + progress + '% done')
            setProgress(progress)
        },
        (_error) => {
          setIsLoading(false)
          setIsError(_error)
        },
        // on success:
        async () => {
          setIsError(false)
          setIsLoading(false)
          // need to get the url to download the file
          let downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
          // set the data when upload has completed
          setDataResponse({
            metadata: uploadTask.snapshot.metadata,
            downloadUrl,
          })
          // reset progress
          setProgress(0)
        }
      )
    } catch (_error) {
      console.error(_error)
      setIsLoading(false)
      setIsError(_error)
    }
  }, [fileData])

  // this function will be called when the any properties in the dependency array changes
  useEffect(() => {
    fileData && uploadData()
  }, [fileData])

  return [
    { dataResponse, isLoading, isError, progress },
    setFileData,
    deleteFile,
    clearError,
  ]
}

export default useFirebaseUpload
