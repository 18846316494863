/* eslint-disable react/prop-types */
import React from 'react'
import { useField } from 'formik'
import ImageUpload from './ImageUpload'
import { schemaArtwork, texts } from './BewerbungFormDefinition'

const ErrorMessage = ({ meta }) => {
  if (meta.touched && meta.error && typeof meta.error === 'string')
    return <div className="invalid-feedback d-block">{meta.error}</div>
  return null
}

const BewerbungFormArtworkFile = ({
  artworkArrayIndex,
  filesArrayIndex,
  formLanguage,
  imgHeight = '80px',
}) => {
  const [fileField, fileMeta, fileHelpers] = useField(
    `artworks[${artworkArrayIndex}].files[${filesArrayIndex}]`
  )
  const onFileChange = (fileName) => {
    if (!fileMeta.touched) fileHelpers.setTouched(true)
    fileHelpers.setValue(fileName?.length > 0 ? fileName : 'deleted')
  }

  if (fileField.value === 'deleted') return null
  return (
    <>
      <ImageUpload
        filename={fileField.value}
        setExternalFileName={onFileChange}
        dropzoneClassName="w-100"
        imgHeight={imgHeight}
        buttonText={
          filesArrayIndex > 0
            ? texts[formLanguage].addAdditionalFile
            : schemaArtwork.files[formLanguage]
        }
      />
      <ErrorMessage meta={fileMeta} />
    </>
  )
}

export default BewerbungFormArtworkFile
