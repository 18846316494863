import * as Yup from 'yup'

const VITA_SHORT_MAX_LENGTH = 300

const getSchemaField = (schema, field) =>
  Object.entries(schema).reduce((curr, [key, value]) => {
    curr[key] = value[field]
    return curr
  }, {})

Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true
    }

    if (
      this.parent
        .filter((v) => v !== value)
        .some((v) => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      })
    }

    return true
  })
})

Yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: 'field_invalid',
    required: 'Feld benötigt',
  },
  // use functions to generate an error object that includes the value from the schema
  // number: {
  //   min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
  //   max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
  // },
})

export const schemaArtwork = {
  title: { initial: '', schema: null, de: 'Titel *', en: 'Title *' },
  technique: { initial: '', schema: null, de: 'Technik *', en: 'Techniques *' },
  height: {
    initial: '',
    schema: null,
    de: 'Höhe in cm *',
    en: 'Height in cm *',
  },
  width: {
    initial: '',
    schema: null,
    de: 'Breite in cm *',
    en: 'Width in cm *',
  },
  depth: { initial: '', schema: null, de: 'Tiefe in cm', en: 'Depth in cm' },
  price: { initial: '', schema: null, de: 'Preis in € *', en: 'Price in € *' },
  info: { initial: '', schema: null, de: 'Hinweis / Info', en: 'Note' },
  files: {
    initial: [],
    schema: null,
    de: 'Datei auswählen oder Drag and Drop',
    en: 'Choose file or drag and drop',
  },
}

export const initialDataArtwork = {
  artworkIndex: 0,
  ...getSchemaField(schemaArtwork, 'initial'),
}

export const schemaContract = {
  tax_number: {
    initial: '',
    schema: Yup.string().required(),
    de: 'Steuernummer oder Steueridentifikationsnummer',
    en: 'Tax number or tax identification number',
  },
  tax_office: {
    initial: '',
    schema: Yup.string().required(),
    de: 'Zuständiges Finanzamt',
    en: 'Responsible tax office',
  },
  liability: {
    initial: null,
    schema: Yup.string().required(),
    de: 'Bist Du umsatzsteuerpflichtig? ',
    en: 'Are you liable to VAT?',
  },
  account_holder: {
    initial: '',
    schema: Yup.string().required(),
    de: 'Kontoinhaber (Klarname, kein Künstlername!)',
    en: "Account holder (real name, no artist's name!)",
  },
  iban: {
    initial: '',
    schema: Yup.string().required(),
    de: 'IBAN',
    en: 'IBAN',
  },
  bic: {
    initial: '',
    schema: Yup.string().required(),
    de: 'BIC',
    en: 'BIC',
  },
  bank_name: {
    initial: '',
    schema: Yup.string().required(),
    de: 'Name des Kreditinstituts',
    en: 'Name of bank',
  },
  // consentGTC_20231: {
  //   // initial: false,
  //   schema: Yup.boolean().oneOf([true]).required(),
  //   de: 'Ich habe die AGBs gelesen, verstanden und akzeptiere diese. ',
  //   en: 'I have read, understood and accept the GTC.',
  // },
  consentInsurance: {
    // initial: false,
    schema: Yup.boolean().required(),
    de: '',
    en: '',
  },
  consentContract: {
    // initial: false,
    schema: Yup.boolean().required(),
    de: 'Ich habe die AGBs gelesen, verstanden und akzeptiere diese. ',
    en: 'I have read, understood and accept the GTC.',
  },
  infoCards: {
    initial: 0,
    schema: Yup.number().required().min(0,'no negative'),
    de: '',
    en: '',
  },
}
export const initialDataContract = getSchemaField(schemaContract, 'initial')

export const schemaArtist = {
  first_name: {
    initial: '',
    schema: null,
    de: 'Vorname *',
    en: 'First name *',
  },
  last_name: {
    initial: '',
    schema: null,
    de: 'Nachname * ',
    en: 'Last name *',
  },
  birthday: { initial: new Date(), schema: null, de: 'Geburtstag *', en: 'Birthday *' },
  address: { initial: '', schema: null, de: 'Straße & Nr. *', en: 'Address *' },
  zipcode: { initial: '', schema: null, de: 'PLZ *', en: 'Postcode *' },
  city: { initial: '', schema: null, de: 'Ort *', en: 'City *' },
  phone: { initial: '', schema: null, de: 'Festnetz', en: 'Phone' },
  mobile: { initial: '', schema: null, de: 'Handy *', en: 'Mobile *' },
  email: { initial: '', schema: null, de: 'E-Mail-Adresse *', en: 'Email *' },
  homepage: {
    initial: '',
    schema: null,
    de: 'Homepage/Social Media',
    en: 'Homepage/Social media',
  },
  instagram: {
    initial: '',
    schema: null,
    de: '@instagram',
    en: '@instagram ',
  },
  info: {
    initial: '',
    schema: null,
    de: 'Hinweise zu meiner Bewerbung (optional)',
    en: 'Additional notes to your application (optional)',
  },
  vita_short: {
    initial: '',
    schema: null,
    de: `Kurzvita für den Katalog (max. ${VITA_SHORT_MAX_LENGTH} Zeichen). Drag and Drop möglich. *`,
    en: `Short CV (max. ${VITA_SHORT_MAX_LENGTH} characters). Drag and Drop is possible. *`,
  },
  vita_long: {
    initial: '',
    schema: null,
    de: 'Komplette Vita (Zeichen unbegrenzt). Drag and Drop möglich.  *',
    en: 'Complete CV (unlimited characters). Drang and Drop is posible. *',
  },
  portrait_file: {
    initial: '',
    schema: null,
    de: 'Datei auswählen oder Drag and Drop *',
    en: 'Choose file or drag and drop *',
  },
  artworks: {
    initial: [initialDataArtwork],
    schema: null,
    de: 'Arbeiten',
    en: 'Works',
  },
}
export const initialDataArtist = getSchemaField(schemaArtist, 'initial')

export const validationSchemaContract = Yup.object().shape({
  birthday: Yup.date().required(),
  // tax_number: Yup.string().required(),
  // tax_office: Yup.string().required(),
  // account_holder: Yup.string().required(),
  // iban: Yup.iban().required(),
  // bic: Yup.string().required(),
  // bank_name: Yup.string().required(),
  ...getSchemaField(schemaContract, 'schema'),
})

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  birthday: Yup.date().required(),
  address: Yup.string().required(),
  zipcode: Yup.string().max(6, 'Maximal 6 Zeichen').required('Feld benötigt'),
  city: Yup.string().required(),
  phone: Yup.string(),
  mobile: Yup.string().required('Mindestens eine Telefonnummer benötigt'),
  email: Yup.string().email('Ungültige E-Mail-Adresse').required(),
  homepage: Yup.string(),
  info: Yup.string(),
  vita_short: Yup.string()
    .max(VITA_SHORT_MAX_LENGTH, `Maximal ${VITA_SHORT_MAX_LENGTH} Zeichen`)
    .required('Kurzvita benötigt'),
  vita_long: Yup.string().required('Vita benötigt'),
  portrait_file: Yup.string().required('Portraitfoto benötigt'),
  artworks: Yup.array()
    .of(
      Yup.object()
        .shape({
          title: Yup.string().required(),
          technique: Yup.string().required(),
          height: Yup.number()
            .typeError('Nur Zahlen erlaubt')
            .positive('Nur positiver Wert erlaubt')
            .required(),
          width: Yup.number()
            .typeError('Nur Zahlen erlaubt')
            .positive('Nur positiver Wert erlaubt')
            .required(),
          depth: Yup.number()
            .typeError('Nur Zahlen erlaubt')
            .positive('Nur positiver Wert erlaubt'),
          price: Yup.number()
            .typeError('Nur Zahlen erlaubt')
            .positive('Nur positiver Wert erlaubt')
            .required(),
          info: Yup.string(),
          files: Yup.array().of(Yup.string()).required().min(1).max(15),
        })
        .uniqueProperty('title', 'Titel ist bereits vergeben. Bitte ändern.')
    )
    // these constraints are shown only if inner constraints allright
    .required('Es müssen Arbeiten angehangen werden')
    // .min(3, 'Es werden mindestens 3 Arbeiten benötigt')
    .max(150, 'Maximal 150 Arbeiten möglich'),
})

export const texts = {
  de: {
    bigHeading: 'ONLINE-BEWERBUNG',
    bigHeadingLogin: 'Künstler-Login',
    bigHeadingContract: 'Steuer- und Bankdaten', //'Vertragsbestätigung',
    buttonToContractConfirmation: 'Zur Vertragsbestätigung',
    descriptionTop1a: 'Bitte lade hier Deine Daten online hoch.',
    descriptionTop1b:
      'Zur Umsetzung der umfangreichen Online-Galerien müssen die Daten über die Homepage erfasst werden. Das ist neu aber ganz einfach und funktioniert schnell. Hier müssen nun auch die einzelnen Bilder hochgeladen werden. Pflichtfelder sind mit Sternchen markiert.',
    descriptionTop2a: 'Bereits beworben?',
    descriptionTop2b1: 'Bitte sei so gut, und lade hier',
    descriptionTop2b2: ' noch einmal ',
    descriptionTop2b3:
      'deine Daten und Bilder hoch, sofern Du uns schon eine Bewerbung gemailt haben solltest. Entschuldige bitte den Mehraufwand.',
    descriptionTop3a: 'Einsendeschluss',
    descriptionTop3b: 'für Deine Bewerbung ist der 16.9.2024',
    forwardToLogin:
      'Du hast bereits teilgenommen und eine Künstler-ID? Folge hier um direkt deine letzte Bewerbung zu aktualisieren.',
    radioChoiceHeading: 'Wie möchtest du starten?',
    radioChoiceHeadingLogin:
      'Bitte gib deine Künstler-ID ein, um deine Daten zu bearbeiten:',
    radioChoiceRestart: 'Um von vorne zu beginnen bitte Seite neu laden.',
    radioChoiceRestartWithOther:
      'Um mit einer anderen Künstler-ID zu starten, bitte Seite neu laden.',
    radioChoiceNew: 'Neue Bewerbung',
    radioChoiceWithID: 'Bewerbung mit Künstler-ID',
    radioChoiceError:
      'Zu dieser Künstler-ID konnten keine Daten gefunden werden.',
    headingPersonalData: 'Meine persönlichen Daten',
    headingMyArtworks: 'Meine Arbeiten',
    headingMyVita: 'Meine Vita',
    headingAddPortraitFile: 'Mein Portraitfoto',
    descriptionArtworkData1:
      'Bitte lade hier alle Arbeiten separat hoch. Denke daran, deine Bilder werden so veröffentlicht, wie du sie schickst. Stimmen Bildausschnitt und Farben? Groß- und Kleinschreibung beachten!',
    descriptionArtworkData2:
      'Jede Arbeit verlangt zur ordnungsgemäßen Weiterverarbeitung zwingend die mit Stern gekennzeichneten Felder und eine korrekt hochgeladene Datei.',
    browsers1: 'Unterstützte Browser:',
    browsers2:
      'Google Chrome, Mozilla Firefox, Internet Explorer 11 (oder neuer)',
    addArtwork: '+ Arbeit hinzufügen',
    addAdditionalFile: '+ weiteres Bild hinzufügen', // TODO 'weiteres Bild zur Arbeit hinzufügen',
    fetchPreloadText: 'Du kannst die Arbeiten deiner letzten Bewerbung laden:',
    fetchPreloadButton: 'Laden',
    fetchPreloadSuccess: 'Arbeiten erfolgreich geladen.',
    fetchPreloadFailure: 'Keine Arbeiten gefunden.',
    submitButton: 'Abschicken',
    submittedSuccess:
      'Deine Bewerbung wurde erfolgreich gesendet. Vielen Dank!',
    submittedSuccessContract:
      'Deine Daten wurden erfolgreich gesendet. Vielen Dank!',
    submittedInvalid1:
      'Das Formular hat noch Fehler oder leere Felder und kann daher nicht abgeschickt werden.',
    submittedInvalid2: 'Bei bleibenden Problemen sende uns bitte eine',
    submittedInvalid3: 'Mail',
    submittedFailure1:
      'Das Formular konnte aus technischen Gründen nicht gesendet werden. Bitte sende uns eine',
    submittedFailure2: 'Mail',
    dataSecurity1: 'Datenschutz',
    dataSecurity2:
      'Die Daten werden nur im Zusammenhang mit der KUBOSHOW KUNSTMSSE 2024 intern verarbeitet, danach gelöscht und nicht an Dritte weitergegeben.',
    headingTaxData: 'Deine Steuerdaten',
    headingBankData: 'Deine Kontoverbindung',
    radioChoiceHeadingContract:
      'Bitte gib deine Künstler-ID ein, um deine Steuer- und Bankdaten zu ergänzen:',
    radioChoiceLiableNo: 'nein, ich bin nicht umsatzsteuerpflichtig ',
    radioChoiceLiableYes7: 'ja, mit 7% MwSt.',
    radioChoiceLiableYes19: 'ja, mit 19% MwSt.',
    contractGTCHeading1: 'AGBs akzeptieren. ',
    contractGTCHeading2: 'Hier besiegeln wir unsere Zusammenarbeit',
    contractGTCMissing: 'Zustimmung zu den AGBs benötigt',
    headingConsentInsurance: 'Deine Versicherung',
    radioChoiceConsentInsuranceYes:
      'JA, ich möchte pauschal meine Arbeiten für 40 € versichern. Die Prämie überweise ich bis zum 15.10.24.',
    radioChoiceConsentInsuranceNo: 'NEIN, ich möchte meine Arbeiten nicht versichern.',
    insuranceAccount: 'Kontoinhaber',
    headingConsentContract: 'Unser Vertrag',
    radioChoiceConsentContractYes:
      'JA, ich akzeptiere den Vertrag, so wie er mir im September 2023 per Mail übermittelt worden ist.',
    radioChoiceConsentContractNo:
      'Nein, ich akzeptiere den Vertrag nicht und nehme auch nicht an der KUBOSHOW KUNSTMESSE teil.',
    headingInfoCards: 'Infokarten',
    infoCards1: 'Ja, ich möchte gerne',
    infoCards2: 'Stück Infokarten kostenfrei zugeschickt bekommen, um damit Menschen postalisch informieren zu können.',
    consentInsuranceMissing: 'Bitte eine Auswahl zur Versicherung treffen',
    consentContractMissing: 'Bitte eine Auswahl zum Vertrag treffen',
    liabilityMissing: 'Bitte eine Auswahl zur Mehrwertsteuerpflicht treffen',
  },
  en: {
    bigHeading: 'ONLINE APPLICATION',
    bigHeadingLogin: 'Artist login',
    bigHeadingContract: 'Tax and bank data', //'Contract confirmation',
    buttonToContractConfirmation: 'To Contract confirmation',
    descriptionTop1a: 'Please upload your data online here.',
    descriptionTop1b:
      'To implement the extensive online galleries, the data must be entered via the homepage. Here you have to upload the single pictures as well. Here you have to upload the single pictures as well. Required fields are marked with an asterisk.',
    descriptionTop2a: 'Already applied?',
    descriptionTop2b1: 'Please be so kind and upload your data and pictures',
    descriptionTop2b2: ' here again',
    descriptionTop2b3:
      ', if you have already sent us an application. Please excuse the additional effort.',
    descriptionTop3a: 'The closing date',
    descriptionTop3b: 'for your application is 16th September 2024.',
    forwardToLogin:
      'Returning artist? You can now avoid work! Access your data from the last fair with your personal artist ID.',
    radioChoiceHeading: 'How do you want to start?',
    radioChoiceHeadingLogin: 'Please enter your Artist ID to edit your data:',
    radioChoiceRestart:
      'Please reload the site to start your choice from scratch.',
    radioChoiceRestartWithOther:
      'Please reload the site to start with another Artist ID',
    radioChoiceNew: 'New application',
    radioChoiceWithID: 'Application with Artist ID',
    radioChoiceError: 'No data found for this Artist ID.',
    headingPersonalData: 'My personal data',
    headingMyArtworks: 'My artworks',
    headingMyVita: 'My CV',
    headingAddPortraitFile: 'My portrait picture',
    descriptionArtworkData1:
      'Please upload all work separately here. Remember, your pictures will be published as you send them. Are the cropping and colours right? Please note that your words are written in upper and lower cases!',
    descriptionArtworkData2:
      'For proper processing, each work requires the fields marked with an * and a correctly uploaded file.',
    browsers1: 'Supported browsers',
    browsers2:
      'Google Chrome, Mozilla Firefox, Internet Explorer 11 (or later)',
    addArtwork: '+ Add artwork',
    addAdditionalFile: 'add additional image',
    submitButton: 'Submit',
    submittedSuccess:
      'Your application has been successfully completed. Thank you very much!',
    submittedSuccessContract:
      'Your data has been sent successfully. Thank you very much!',
    submittedInvalid1:
      'Your application cannot be submitted due to missing or invalid inputs.',
    submittedInvalid2: 'If the problem remains please ask us for help via',
    submittedInvalid3: 'mail',
    submittedFailure1:
      'Due to technical reasons your application could not be sent. Please let us know via',
    submittedFailure2: 'mail',
    dataSecurity1: 'Privacy',
    dataSecurity2:
      'The data will only be processed internally in connection with the KUBOSHOW KUNSTMSSE 2024, then deleted and not passed on to third parties.',
    headingTaxData: 'Your tax data',
    headingBankData: 'Your bank account details',
    radioChoiceHeadingContract:
      'Please enter your Artist ID to edit your tax and bank data:',
    radioChoiceLiableNo: 'no, I am not liable to VAT',
    radioChoiceLiableYes7: 'yes, with 7% VAT',
    radioChoiceLiableYes19: 'yes, with 19% VAT',
    contractGTCHeading1: 'Accept GTC. ',
    contractGTCHeading2: 'Here we seal our cooperation',
    contractGTCMissing: 'Please accept the GTC',
    headingConsentInsurance: 'Your insurance',
    radioChoiceConsentInsuranceYes:
      'YES, I would like to insure my work for a fixed amount of € 40. I will transfer the premium until 15.10.24.',
    radioChoiceConsentInsuranceNo: 'NO, I do not wish to insure my work.',
    insuranceAccount: 'Account holder', 
    headingConsentContract: 'Our contract',
    radioChoiceConsentContractYes:
      'YES, I accept the contract as it was sent to me by mail in September 2024.',
    radioChoiceConsentContractNo:
      'NO, I do not accept the contract and will not participate in the KUBOSHOW KUNSTMESSE.',
    headingInfoCards: 'Info cards',
    infoCards1:'Yes, I would like to have',
    infoCards2:'info cards sent to me free of charge so that I can inform people by post.',
    consentInsuranceMissing: 'Please choose one option for your insurance',
    consentContractMissing: 'Please choose one option for your contract',
    liabilityMissing: 'Please choose one option for your liability',
  },
}
